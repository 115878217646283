import { useState, useCallback } from 'react'

const useForm = () => {
  const [submitting, setSubmitting] = useState(false)
  const [success, setSuccess] = useState()

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);

    const name = e.target.elements[0].value;
    const email = e.target.elements[1].value;
    const message = e.target.elements[2].value;

    const data = {
      name: name,
      email_address: email,
      message: message,
    };

    fetch(
      'https://app.simplereviewmanagement.com/contact-form',
      {
        method: 'POST',
        credentials: 'include',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      },
    ).then(response => response.json()).then(() => {
      setSuccess(true);
      setSubmitting(false);
    }).catch(() => {
      setSuccess(false);
      setSubmitting(false);
    });
  }

  const reset = useCallback(() => {
    setSubmitting(false)
    setSuccess(undefined)
  }, [])

  return {
    handleSubmit,
    submitting,
    success,
    reset
  }
}

export default useForm
